@charset 'utf-8';

// prerequisites

@import '~sass-mq';
@import 'abstracts/*';

// vendors

@import-normalize;

@import 'vendors/*';
@import url('https://cdn.jsdelivr.net/npm/yakuhanjp@3.2.0/dist/css/yakuhanjp-noto.min.css');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500');

// categorized rules

@import 'utils/*';
@import 'base/*';
@import 'layout/*';
@import 'components/*';
