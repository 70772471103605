@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  clip-path: inset(50%);
}

@mixin clearfix {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}

@mixin button-sm($color: #fff, $width: 100px, $height: 50px) {
  box-sizing: border-box;
  display: inline-block;
  width: $width;
  height: $height;
  overflow: hidden;
  font-size: 14px;
  line-height: 1;
  color: $color;
  letter-spacing: 0.08em;
  vertical-align: top;
  border: 1px solid $color;
  border-radius: $height / 2;

  @include mq(md) {
    transition: background-color 300ms $ease-out-quad,
      color 300ms $ease-out-quad;

    &:hover {
      background-color: $color;

      @if $color != #fff {
        color: #fff;
      } @else {
        color: #04612e;
      }
    }
  }

  &-inner,
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &-label,
  &__label {
    position: relative;
    top: -0.1em;
  }
}

@mixin button-md($width: 100px, $height: 53px) {
  width: $width;
  height: $height;
  font-size: 12px;
  letter-spacing: 0.08em;
  border-radius: $height / 2;
}

@mixin button-fill-sm($bgcolor: #fff, $textcolor: #fff, $width: 100px, $height: 60px, $fontsize: 14px) { //stylelint-disable-line
  box-sizing: border-box;
  display: inline-block;
  width: $width;
  height: $height;
  overflow: hidden;
  font-size: $fontsize;
  line-height: 1;
  color: $textcolor;
  letter-spacing: 0.08em;
  vertical-align: top;
  background-color: $bgcolor;
  border: 1px solid $bgcolor;
  border-radius: $height / 2;

  @include mq(md) {
    transition: background-color 300ms $ease-out-quad,
      color 300ms $ease-out-quad, border-color 300ms $ease-out-quad;

    &:hover {
      color: $bgcolor;
      background-color: $textcolor;
      border-color: $bgcolor;
    }
  }

  &-inner,
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &-label,
  &__label {
    position: relative;
  }
}

@mixin button-fill-md($width: 100px, $height: 74px, $fontsize: 16px) {
  width: $width;
  height: $height;
  font-size: $fontsize;
  letter-spacing: 0.08em;
  border-radius: $height / 2;
}

@mixin container {
  width: 1200px;
  margin: 0 auto;
}
