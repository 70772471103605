@import url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.2.0/dist/css/yakuhanjp-noto.min.css");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500");
@import-normalize;
.u-clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.u-d-none {
  display: none !important;
}

.u-d-inline {
  display: inline !important;
}

.u-d-inline-block {
  display: inline-block !important;
}

.u-d-block {
  display: block !important;
}

.u-d-table {
  display: table !important;
}

.u-d-table-row {
  display: table-row !important;
}

.u-d-table-cell {
  display: table-cell !important;
}

.u-d-flex {
  display: flex !important;
}

.u-d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 23.4375em) {
  .u-d-sm-none {
    display: none !important;
  }

  .u-d-sm-inline {
    display: inline !important;
  }

  .u-d-sm-inline-block {
    display: inline-block !important;
  }

  .u-d-sm-block {
    display: block !important;
  }

  .u-d-sm-table {
    display: table !important;
  }

  .u-d-sm-table-row {
    display: table-row !important;
  }

  .u-d-sm-table-cell {
    display: table-cell !important;
  }

  .u-d-sm-flex {
    display: flex !important;
  }

  .u-d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 48em) {
  .u-d-md-none {
    display: none !important;
  }

  .u-d-md-inline {
    display: inline !important;
  }

  .u-d-md-inline-block {
    display: inline-block !important;
  }

  .u-d-md-block {
    display: block !important;
  }

  .u-d-md-table {
    display: table !important;
  }

  .u-d-md-table-row {
    display: table-row !important;
  }

  .u-d-md-table-cell {
    display: table-cell !important;
  }

  .u-d-md-flex {
    display: flex !important;
  }

  .u-d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 80em) {
  .u-d-lg-none {
    display: none !important;
  }

  .u-d-lg-inline {
    display: inline !important;
  }

  .u-d-lg-inline-block {
    display: inline-block !important;
  }

  .u-d-lg-block {
    display: block !important;
  }

  .u-d-lg-table {
    display: table !important;
  }

  .u-d-lg-table-row {
    display: table-row !important;
  }

  .u-d-lg-table-cell {
    display: table-cell !important;
  }

  .u-d-lg-flex {
    display: flex !important;
  }

  .u-d-lg-inline-flex {
    display: inline-flex !important;
  }
}
.u-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  clip-path: inset(50%);
}

* {
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  min-width: 375px;
}
@media (min-width: 48em) {
  html {
    min-width: 1280px;
  }
}

body {
  font-family: "YakuHanJP_Noto", "Noto Sans JP", sans-serif;
  font-weight: 300;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

ol,
ul {
  list-style: none;
}

a {
  color: currentColor;
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

svg:not([fill]) {
  fill: currentColor;
}

table {
  border-collapse: collapse;
}

[data-whatinput=mouse] :focus {
  outline: none;
}

*,
::before,
::after {
  box-sizing: inherit;
  background-repeat: no-repeat;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
}

.l-page {
  padding-top: 48px;
}
@media (min-width: 48em) {
  .l-page {
    padding-top: 0;
  }
}

.c-contact {
  position: relative;
  overflow: hidden;
  color: #fff;
  background-color: #196537;
}
.c-contact__inner {
  position: relative;
  padding: 67px 25px 76px;
}
@media (min-width: 48em) {
  .c-contact__inner {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding: 84px 0 135px;
  }
}
@media (min-width: 48em) {
  .c-contact__block:nth-of-type(1) {
    width: 739px;
  }
}
@media (min-width: 48em) {
  .c-contact__block:nth-of-type(2) {
    width: 461px;
    padding-left: 56px;
  }
}
.c-contact__heading {
  position: absolute;
  top: 0;
  left: 0;
}
.c-contact::before {
  position: absolute;
  top: -9px;
  left: 0;
  display: block;
  width: 101px;
  height: 450.5px;
  content: "";
  background-image: url("/assets/images/pages/common/contact_typo_sm.png");
  background-size: 101px 450.5px;
}
@media (min-width: 48em) {
  .c-contact::before {
    top: auto;
    bottom: -8px;
    width: 1241px;
    height: 288px;
    background-image: url("/assets/images/pages/common/contact_typo_md.png");
    background-size: auto;
  }
}
.c-contact .mail {
  border-bottom: 1px solid #fff;
}
@media (min-width: 48em) {
  .c-contact .mail {
    margin-top: 32px;
  }
}
.c-contact .mail__link {
  display: block;
  padding: 0 0 22px;
  text-align: center;
}
@media (min-width: 48em) {
  .c-contact .mail__link {
    display: flex;
    align-items: center;
    padding: 30px 0 58px 1px;
  }
}
.c-contact .mail__icon {
  display: block;
}
@media (min-width: 48em) {
  .c-contact .mail__icon img {
    width: auto;
    height: auto;
  }
}
.c-contact .mail__label {
  display: block;
  margin-top: 18px;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0.05em;
}
@media (min-width: 48em) {
  .c-contact .mail__label {
    position: relative;
    top: -2px;
    margin-top: 0;
    margin-left: 30px;
    font-size: 22px;
  }
}
.c-contact .branches {
  display: flex;
  padding: 36px 0 0;
}
.c-contact .branch {
  width: 50%;
}
.c-contact .branch__name {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.07em;
}
@media (min-width: 48em) {
  .c-contact .branch__name {
    font-size: 17px;
  }
}
.c-contact .branch__info {
  margin-top: 9px;
}
@media (min-width: 48em) {
  .c-contact .branch__info {
    margin-top: 20px;
  }
}
.c-contact .branch__contact {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1;
}
@media (min-width: 48em) {
  .c-contact .branch__contact {
    font-size: 15px;
  }
}
.c-contact .branch__contact + .branch__contact {
  margin-top: 9px;
}
@media (min-width: 48em) {
  .c-contact .branch__contact + .branch__contact {
    margin-top: 11px;
  }
}
.c-contact .branch__contact-number {
  position: relative;
  top: 1px;
  margin-left: 10px;
  letter-spacing: 0.11em;
  white-space: nowrap;
}
@media (min-width: 48em) {
  .c-contact .branch__contact-number {
    margin-left: 14px;
  }
}
.c-contact .branch__charge {
  margin-top: 10px;
  font-size: 12px;
  letter-spacing: 0.08em;
}
@media (min-width: 48em) {
  .c-contact .branch__charge {
    margin-top: 14px;
    font-size: 14px;
    letter-spacing: 0.12em;
  }
}
.c-contact .branch:nth-child(1) {
  padding-left: 5px;
}
.c-contact .branch:nth-child(2) {
  padding-left: 9px;
}
@media (min-width: 48em) {
  .c-contact .branch:nth-child(2) {
    padding-left: 19px;
  }
}

.footer {
  background-color: #242926;
}
.footer__inner {
  padding: 38.5px 25px 40px;
}
@media (min-width: 48em) {
  .footer__inner {
    width: 1200px;
    margin: 0 auto;
    padding: 78px 0 101px;
  }
}
.footer__company {
  margin-top: 55px;
  text-align: center;
}
@media (min-width: 48em) {
  .footer__company {
    margin-top: 43px;
  }
}
@media (min-width: 48em) {
  .footer__company img {
    position: relative;
    left: -6px;
    width: auto;
    height: auto;
  }
}
.footer__copyright {
  margin-top: 6px;
  text-align: center;
}
@media (min-width: 48em) {
  .footer__copyright {
    margin-top: 14px;
  }
}
@media (min-width: 48em) {
  .footer__copyright img {
    position: relative;
    left: -6px;
  }
}
@media (min-width: 48em) {
  .footer .primary-nav {
    display: flex;
  }
}
.footer .primary-nav__item {
  border-top: 1px solid #3a3f3c;
}
@media (min-width: 48em) {
  .footer .primary-nav__item {
    border-top: 0;
  }
}
@media (min-width: 48em) {
  .footer .primary-nav__item:nth-child(1) {
    width: 98px;
  }
}
@media (min-width: 48em) {
  .footer .primary-nav__item:nth-child(2) {
    width: 417px;
  }
}
@media (min-width: 48em) {
  .footer .primary-nav__item:nth-child(3) {
    width: 269px;
  }
}
@media (min-width: 48em) {
  .footer .primary-nav__item:nth-child(4) {
    width: 178px;
  }
}
.footer .primary-item {
  position: relative;
  display: block;
  padding: 20px 0;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
}
@media (min-width: 48em) {
  .footer .primary-item {
    font-size: 14px;
  }
  .footer .primary-item:hover {
    text-decoration: underline;
  }
}
@media (min-width: 48em) {
  .footer .primary-item[data-collapse-toggler] {
    pointer-events: none;
  }
}
.footer .primary-item[data-collapse-toggler]::before, .footer .primary-item[data-collapse-toggler]::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  display: block;
  width: 16px;
  height: 1px;
  margin: auto 0;
  content: "";
  background-color: #fff;
}
@media (min-width: 48em) {
  .footer .primary-item[data-collapse-toggler]::before, .footer .primary-item[data-collapse-toggler]::after {
    display: none;
  }
}
.footer .primary-item[data-collapse-toggler]::after {
  transition: transform 300ms;
  transform: rotate(-90deg);
}
.footer .primary-item[aria-expanded=true]::after {
  transform: rotate(0);
}
.footer .secondary-navWrapper:not([aria-hidden=false]) {
  height: 0;
  overflow: hidden;
}
@media (min-width: 48em) {
  .footer .secondary-navWrapper:not([aria-hidden=false]) {
    height: auto !important;
    visibility: visible !important;
  }
}
.footer .secondary-nav {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
}
@media (min-width: 48em) {
  .footer .secondary-nav {
    padding-top: 1px;
  }
}
.footer .secondary-nav__item {
  width: 50%;
  line-height: 1;
}
.footer .secondary-nav__item:nth-child(n+3) {
  margin-top: 11px;
}
@media (min-width: 48em) {
  .footer .secondary-nav__item:nth-child(n+3) {
    margin-top: 14px;
  }
}
@media (min-width: 48em) {
  .footer .secondary-nav--01 .secondary-nav__item:nth-child(odd) {
    width: 168px;
  }
}
@media (min-width: 48em) {
  .footer .secondary-nav--02 .secondary-nav__item:nth-child(odd) {
    width: 102px;
  }
}
.footer .secondary-item {
  position: relative;
  display: block;
  padding: 0 0 0 14px;
  font-size: 12px;
  line-height: 1.23;
  color: #666967;
  letter-spacing: 0.1em;
}
@media (min-width: 48em) {
  .footer .secondary-item {
    line-height: 1.5;
  }
  .footer .secondary-item:hover {
    text-decoration: underline;
  }
}
.footer .secondary-item::before {
  position: absolute;
  top: 8px;
  left: 1px;
  display: block;
  width: 3px;
  height: 1px;
  content: "";
  background-color: #666967;
}
@media (min-width: 48em) {
  .footer .secondary-item::before {
    top: 10px;
  }
}
.footer .other-nav {
  display: flex;
  flex-wrap: wrap;
  width: 240px;
  padding: 27px 0 0;
  margin: 0 auto;
}
@media (min-width: 48em) {
  .footer .other-nav {
    display: block;
    width: auto;
    padding: 17px 0 0;
  }
}
@media (min-width: 48em) {
  .footer .other-nav__item {
    width: auto !important;
  }
}
.footer .other-nav__item:nth-child(1) {
  order: 1;
  width: 110px;
}
.footer .other-nav__item:nth-child(2) {
  order: 3;
  width: 110px;
  margin-top: 3px;
}
.footer .other-nav__item:nth-child(3) {
  order: 2;
}
.footer .other-nav__item:nth-child(4) {
  order: 4;
  margin-top: 3px;
}
.footer .other-nav__item:nth-child(5) {
  order: 5;
  width: 100%;
  margin-top: 31px;
  text-align: center;
}
@media (min-width: 48em) {
  .footer .other-nav__item:nth-child(5) {
    margin-top: 38px !important;
  }
}
@media (min-width: 48em) {
  .footer .other-nav__item + .other-nav__item {
    margin-top: 14px;
  }
}
.footer .other-item {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.08em;
}
@media (min-width: 48em) {
  .footer .other-item:hover {
    text-decoration: underline;
  }
}
.footer .other-nav-button {
  box-sizing: border-box;
  display: inline-block;
  width: 203.5px;
  height: 50px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1;
  color: #fff;
  letter-spacing: 0.08em;
  vertical-align: top;
  border: 1px solid #fff;
  border-radius: 25px;
}
@media (min-width: 48em) {
  .footer .other-nav-button {
    transition: background-color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94), color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .footer .other-nav-button:hover {
    background-color: #fff;
    color: #04612e;
  }
}
.footer .other-nav-button-inner, .footer .other-nav-button__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.footer .other-nav-button-label, .footer .other-nav-button__label {
  position: relative;
  top: -0.1em;
}
@media (min-width: 48em) {
  .footer .other-nav-button {
    width: 238px;
    height: 53px;
    font-size: 12px;
    letter-spacing: 0.08em;
    border-radius: 26.5px;
  }
}

.header {
  position: relative;
  height: 48px;
  background-color: #fff;
  transition: background-color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media (min-width: 48em) {
  .header {
    height: auto;
    padding-bottom: 27px;
    background-color: transparent;
  }
}
.header__inner {
  display: flex;
}
.header__logo {
  position: relative;
  padding: 12px 0 0 17px;
  margin: 0;
  font-size: 10px;
  line-height: 1;
}
@media (min-width: 48em) {
  .header__logo {
    padding: 35px 0 0 50px;
  }
}
@media (min-width: 48em) {
  .header__logo img {
    width: auto;
    height: auto;
  }
}
.header__logo-active {
  position: absolute;
  top: 12px;
  left: 17px;
  z-index: 1;
  opacity: 0;
}
@media (min-width: 48em) {
  .header.is-expanded .header__logo-active {
    display: none;
  }
}

@media (min-width: 48em) {
  .header:not(.header--lower).theme-scrolled .header__logo-active {
    opacity: 1;
  }
}

@media (min-width: 48em) {
  .header:not(.header--lower) .header__logo-active {
    transition: opacity 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}

@media (min-width: 48em) {
  .header__logo-active {
    top: 35px;
    left: 50px;
  }
}
.header__menuButton {
  display: block;
  width: 52px;
  height: 48px;
  margin-left: auto;
  background-color: #196537;
  border: 0;
}
@media (min-width: 48em) {
  .header__menuButton {
    display: none;
  }
}
.header__menuButton-inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.header__menuButton-line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 1px;
  margin: auto;
  background-color: #fff;
  transition: opacity 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.header__menuButton-line--01 {
  top: -14px;
}
.header__menuButton.is-expanded .header__menuButton-line--01 {
  opacity: 0;
}

.header__menuButton.is-expanded .header__menuButton-line--02 {
  opacity: 0;
}

.header__menuButton-line--03 {
  bottom: -14px;
}
.header__menuButton.is-expanded .header__menuButton-line--03 {
  opacity: 0;
}

.header__menuButton-line--04 {
  width: 20px;
  opacity: 0;
  transform: rotate(-45deg);
}
.header__menuButton.is-expanded .header__menuButton-line--04 {
  opacity: 1;
}

.header__menuButton-line--05 {
  width: 20px;
  opacity: 0;
  transform: rotate(45deg);
}
.header__menuButton.is-expanded .header__menuButton-line--05 {
  opacity: 1;
}

.header__nav {
  padding: 27px 50px 0 0;
  margin-left: auto;
}
.header--lower {
  background-color: transparent;
}
.header.is-expanded {
  background-color: #fff;
}
.header.theme-scrolled {
  background-color: #fff;
}
.header .nav {
  display: none;
  align-items: center;
}
@media (min-width: 48em) {
  .header .nav {
    display: flex;
  }
}
.header .nav__item {
  margin-left: 39px;
}
.header .nav__item:last-child {
  margin-left: 39px;
}
.header .nav .item {
  position: relative;
  display: block;
  padding: 10px 0;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.08em;
  transition: color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.header--lower .nav .item {
  color: #000;
}

.header:not(.header--lower).theme-scrolled .nav .item {
  color: #000;
}

.header .nav .item::before {
  position: absolute;
  bottom: 4px;
  left: -3px;
  display: block;
  width: calc(100% + 6px);
  height: 1px;
  content: "";
  background-color: #fff;
  opacity: 0;
  transition: background-color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.header--lower .nav .item::before {
  background-color: #04612e;
}

.header:not(.header--lower).theme-scrolled .nav .item::before {
  background-color: #04612e;
}

@media (min-width: 48em) {
  .header .nav .item::before {
    transition: opacity 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .header .nav .item:hover::before {
    opacity: 1;
  }
}
.header .nav .contact-button {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 60px;
  overflow: hidden;
  font-size: 17px;
  line-height: 1;
  color: #fff;
  letter-spacing: 0.08em;
  vertical-align: top;
  background-color: #04612e;
  border: 1px solid #04612e;
  border-radius: 30px;
}
@media (min-width: 48em) {
  .header .nav .contact-button {
    transition: background-color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94), color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .header .nav .contact-button:hover {
    color: #04612e;
    background-color: #fff;
    border-color: #04612e;
  }
}
.header .nav .contact-button-inner, .header .nav .contact-button__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.header .nav .contact-button-label, .header .nav .contact-button__label {
  position: relative;
}
@media (min-width: 48em) {
  .header .nav .contact-button {
    width: 198px;
    height: 53px;
    font-size: 12px;
    letter-spacing: 0.08em;
    border-radius: 26.5px;
  }
}
.header .menu {
  position: absolute;
  top: 0;
  z-index: -1;
  height: 100vh;
  padding-top: 99px;
  visibility: hidden;
  background-color: #196537;
  opacity: 0;
  transition: opacity 300ms, cubic-bezier(0.25, 0.46, 0.45, 0.94) visibility 300ms;
}
@media (min-width: 48em) {
  .header .menu {
    display: none;
  }
}
.header .menu__inner {
  height: 100%;
  padding: 0 25px;
  overflow: auto;
}
.header .menu.is-expanded {
  visibility: visible;
  opacity: 1;
}
.header .primary-nav {
  padding-bottom: 200px;
}
.header .primary-nav__item {
  border-top: 1px solid #30754b;
}
.header .primary-item {
  position: relative;
  display: block;
  padding: 20px 0;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
}
@media (min-width: 48em) {
  .header .primary-item[data-collapse-toggler] {
    pointer-events: none;
  }
}
.header .primary-item[data-collapse-toggler]::before, .header .primary-item[data-collapse-toggler]::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  display: block;
  width: 16px;
  height: 1px;
  margin: auto 0;
  content: "";
  background-color: #fff;
}
.header .primary-item[data-collapse-toggler]::after {
  transition: transform 300ms;
  transform: rotate(-90deg);
}
.header .primary-item[aria-expanded=true]::after {
  transform: rotate(0);
}
.header .secondary-navWrapper:not([aria-hidden=false]) {
  height: 0;
  overflow: hidden;
}
.header .secondary-nav {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
}
@media (min-width: 48em) {
  .header .secondary-nav {
    padding-top: 1px;
  }
}
.header .secondary-nav__item {
  width: 50%;
  line-height: 1;
}
.header .secondary-nav__item:nth-child(n+3) {
  margin-top: 11px;
}
.header .secondary-item {
  position: relative;
  display: block;
  padding: 0 0 0 14px;
  font-size: 12px;
  line-height: 1.23;
  color: #7c9d87;
  letter-spacing: 0.1em;
}
.header .secondary-item::before {
  position: absolute;
  top: 8px;
  left: 1px;
  display: block;
  width: 3px;
  height: 1px;
  content: "";
  background-color: #7c9d87;
}
.header .other-nav {
  display: flex;
  flex-wrap: wrap;
  width: 240px;
  padding: 27px 0 0;
  margin: 0 auto;
}
@media (min-width: 48em) {
  .header .other-nav__item {
    width: auto !important;
  }
}
.header .other-nav__item:nth-child(1) {
  order: 1;
  width: 110px;
}
.header .other-nav__item:nth-child(2) {
  order: 3;
  width: 110px;
  margin-top: 3px;
}
.header .other-nav__item:nth-child(3) {
  order: 2;
}
.header .other-nav__item:nth-child(4) {
  order: 4;
  margin-top: 3px;
}
.header .other-nav__item:nth-child(5) {
  order: 5;
  width: 100%;
  margin-top: 31px;
  text-align: center;
}
.header .other-item {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.08em;
}
.header .other-nav-button1 {
  box-sizing: border-box;
  display: inline-block;
  width: 203.5px;
  height: 50px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1;
  color: #196537;
  letter-spacing: 0.08em;
  vertical-align: top;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 25px;
}
@media (min-width: 48em) {
  .header .other-nav-button1 {
    transition: background-color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94), color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .header .other-nav-button1:hover {
    color: #fff;
    background-color: #196537;
    border-color: #fff;
  }
}
.header .other-nav-button1-inner, .header .other-nav-button1__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.header .other-nav-button1-label, .header .other-nav-button1__label {
  position: relative;
}
.header .other-nav-button2 {
  box-sizing: border-box;
  display: inline-block;
  width: 203.5px;
  height: 50px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1;
  color: #fff;
  letter-spacing: 0.08em;
  vertical-align: top;
  border: 1px solid #fff;
  border-radius: 25px;
  margin-top: 15px;
}
@media (min-width: 48em) {
  .header .other-nav-button2 {
    transition: background-color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94), color 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .header .other-nav-button2:hover {
    background-color: #fff;
    color: #04612e;
  }
}
.header .other-nav-button2-inner, .header .other-nav-button2__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.header .other-nav-button2-label, .header .other-nav-button2__label {
  position: relative;
  top: -0.1em;
}