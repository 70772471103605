.c-contact {
  position: relative;
  overflow: hidden;
  color: #fff;
  background-color: #196537;

  &__inner {
    position: relative;
    padding: 67px 25px 76px;

    @include mq(md) {
      @include container();

      display: flex;
      padding: 84px 0 135px;
    }
  }

  &__block {
    &:nth-of-type(1) {
      @include mq(md) {
        width: 739px;
      }
    }

    &:nth-of-type(2) {
      @include mq(md) {
        width: 461px;
        padding-left: 56px;
      }
    }
  }

  &__heading {
    position: absolute;
    top: 0;
    left: 0;
  }

  &::before {
    position: absolute;
    top: -9px;
    left: 0;
    display: block;
    width: 101px;
    height: 450.5px;
    content: '';
    background-image: url('/assets/images/pages/common/contact_typo_sm.png');
    background-size: 101px 450.5px;

    @include mq(md) {
      top: auto;
      bottom: -8px;
      width: 1241px;
      height: 288px;
      background-image: url('/assets/images/pages/common/contact_typo_md.png');
      background-size: auto;
    }
  }

  .mail {
    border-bottom: 1px solid #fff;

    @include mq(md) {
      margin-top: 32px;
    }

    &__link {
      display: block;
      padding: 0 0 22px;
      text-align: center;

      @include mq(md) {
        display: flex;
        align-items: center;
        padding: 30px 0 58px 1px;
      }
    }

    &__icon {
      display: block;

      img {
        @include mq(md) {
          width: auto;
          height: auto;
        }
      }
    }

    &__label {
      display: block;
      margin-top: 18px;
      font-size: 23px;
      font-weight: 700;
      letter-spacing: 0.05em;

      @include mq(md) {
        position: relative;
        top: -2px;
        margin-top: 0;
        margin-left: 30px;
        font-size: 22px;
      }
    }
  }

  .branches {
    display: flex;
    padding: 36px 0 0;
  }

  .branch {
    width: 50%;

    &__name {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.07em;

      @include mq(md) {
        font-size: 17px;
      }
    }

    &__info {
      margin-top: 9px;

      @include mq(md) {
        margin-top: 20px;
      }
    }

    &__contact {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 1;

      @include mq(md) {
        font-size: 15px;
      }

      + .branch__contact {
        margin-top: 9px;

        @include mq(md) {
          margin-top: 11px;
        }
      }
    }

    &__contact-number {
      position: relative;
      top: 1px;
      margin-left: 10px;
      letter-spacing: 0.11em;
      white-space: nowrap;

      @include mq(md) {
        margin-left: 14px;
      }
    }

    &__charge {
      margin-top: 10px;
      font-size: 12px;
      letter-spacing: 0.08em;

      @include mq(md) {
        margin-top: 14px;
        font-size: 14px;
        letter-spacing: 0.12em;
      }
    }

    &:nth-child(1) {
      padding-left: 5px;
    }

    &:nth-child(2) {
      padding-left: 9px;

      @include mq(md) {
        padding-left: 19px;
      }
    }
  }
}
