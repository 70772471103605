.footer {
  background-color: #242926;

  &__inner {
    padding: 38.5px 25px 40px;

    @include mq(md) {
      @include container();

      padding: 78px 0 101px;
    }
  }

  &__company {
    margin-top: 55px;
    text-align: center;

    @include mq(md) {
      margin-top: 43px;
    }

    img {
      @include mq(md) {
        position: relative;
        left: -6px;
        width: auto;
        height: auto;
      }
    }
  }

  &__copyright {
    margin-top: 6px;
    text-align: center;

    @include mq(md) {
      margin-top: 14px;
    }

    img {
      @include mq(md) {
        position: relative;
        left: -6px;
      }
    }
  }

  .primary-nav {
    @include mq(md) {
      display: flex;
    }

    &__item {
      border-top: 1px solid #3a3f3c;

      @include mq(md) {
        border-top: 0;
      }

      &:nth-child(1) {
        @include mq(md) {
          width: 98px;
        }
      }

      &:nth-child(2) {
        @include mq(md) {
          width: 417px;
        }
      }

      &:nth-child(3) {
        @include mq(md) {
          width: 269px;
        }
      }

      &:nth-child(4) {
        @include mq(md) {
          width: 178px;
        }
      }
    }
  }

  .primary-item {
    position: relative;
    display: block;
    padding: 20px 0;
    font-size: 15px;
    font-weight: 400;
    color: #fff;

    @include mq(md) {
      font-size: 14px;

      &:hover {
        text-decoration: underline;
      }
    }

    &[data-collapse-toggler] {
      @include mq(md) {
        pointer-events: none;
      }

      &::before,
      &::after {
        position: absolute;
        top: 0;
        right: 1px;
        bottom: 0;
        display: block;
        width: 16px;
        height: 1px;
        margin: auto 0;
        content: '';
        background-color: #fff;

        @include mq(md) {
          display: none;
        }
      }

      &::after {
        transition: transform 300ms;
        transform: rotate(-90deg);
      }
    }

    &[aria-expanded='true'] {
      &::after {
        transform: rotate(0);
      }
    }
  }

  .secondary-navWrapper {
    &:not([aria-hidden='false']) {
      height: 0;
      overflow: hidden;

      @include mq(md) {
        height: auto !important; //stylelint-disable-line
        visibility: visible !important; //stylelint-disable-line
      }
    }
  }

  .secondary-nav {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 24px;

    @include mq(md) {
      padding-top: 1px;
    }

    &__item {
      width: 50%;
      line-height: 1;

      &:nth-child(n + 3) {
        margin-top: 11px;

        @include mq(md) {
          margin-top: 14px;
        }
      }
    }

    &--01 {
      .secondary-nav__item {
        &:nth-child(odd) {
          @include mq(md) {
            width: 168px;
          }
        }
      }
    }

    &--02 {
      .secondary-nav__item { //stylelint-disable-line
        &:nth-child(odd) {
          @include mq(md) {
            width: 102px;
          }
        }
      }
    }
  }

  .secondary-item {
    position: relative;
    display: block;
    padding: 0 0 0 14px;
    font-size: 12px;
    line-height: 1.23;
    color: #666967;
    letter-spacing: 0.1em;

    @include mq(md) {
      line-height: 1.5;

      &:hover {
        text-decoration: underline;
      }
    }

    &::before {
      position: absolute;
      top: 8px;
      left: 1px;
      display: block;
      width: 3px;
      height: 1px;
      content: '';
      background-color: #666967;

      @include mq(md) {
        top: 10px;
      }
    }
  }

  .other-nav {
    display: flex;
    flex-wrap: wrap;
    width: 240px;
    padding: 27px 0 0;
    margin: 0 auto;

    @include mq(md) {
      display: block;
      width: auto;
      padding: 17px 0 0;
    }

    &__item {
      @include mq(md) {
        width: auto !important; //stylelint-disable-line
      }

      &:nth-child(1) {
        order: 1;
        width: 110px;
      }

      &:nth-child(2) {
        order: 3;
        width: 110px;
        margin-top: 3px;
      }

      &:nth-child(3) {
        order: 2;
      }

      &:nth-child(4) {
        order: 4;
        margin-top: 3px;
      }

      &:nth-child(5) {
        order: 5;
        width: 100%;
        margin-top: 31px;
        text-align: center;

        @include mq(md) {
          margin-top: 38px !important; // stylelint-disable-line
        }
      }

      + .other-nav__item {
        @include mq(md) {
          margin-top: 14px;
        }
      }
    }
  }

  .other-item {
    font-size: 12px;
    color: #fff;
    letter-spacing: 0.08em;

    @include mq(md) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .other-nav-button {
    @include button-sm($color: #fff, $width: 203.5px);

    @include mq(md) {
      @include button-md($width: 238px, $height: 53px);
    }
  }
}
