.header {
  position: relative;
  height: 48px;
  background-color: #fff;
  transition: background-color 300ms $ease-out-quad;

  @include mq(md) {
    height: auto;
    padding-bottom: 27px;
    background-color: transparent;
  }

  &__inner {
    display: flex;
  }

  &__logo {
    position: relative;
    padding: 12px 0 0 17px;
    margin: 0;
    font-size: 10px;
    line-height: 1;

    @include mq(md) {
      padding: 35px 0 0 50px;
    }

    img {
      @include mq(md) {
        width: auto;
        height: auto;
      }
    }
  }

  &__logo-default {
    //
  }

  &__logo-active {
    position: absolute;
    top: 12px;
    left: 17px;
    z-index: 1;
    opacity: 0;

    @at-root .header.is-expanded & {
      // opacity: 1;

      @include mq(md) {
        display: none;
      }
    }

    @at-root .header:not(.header--lower).theme-scrolled .header__logo-active {
      @include mq(md) {
        opacity: 1;
      }
    }

    @at-root .header:not(.header--lower) .header__logo-active {
      @include mq(md) {
        transition: opacity 300ms $ease-out-quad;
      }
    }

    @include mq(md) {
      top: 35px;
      left: 50px;
    }
  }

  &__menuButton {
    display: block;
    width: 52px;
    height: 48px;
    margin-left: auto;
    background-color: #196537;
    border: 0;

    @include mq(md) {
      display: none;
    }
  }

  &__menuButton-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__menuButton-line {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 1px;
    margin: auto;
    background-color: #fff;
    transition: opacity 300ms $ease-out-quad;

    &--01 {
      top: -14px;

      @at-root .header__menuButton.is-expanded & {
        opacity: 0;
      }
    }

    &--02 {
      @at-root .header__menuButton.is-expanded & {
        opacity: 0;
      }
    }

    &--03 {
      bottom: -14px;

      @at-root .header__menuButton.is-expanded & {
        opacity: 0;
      }
    }

    &--04 {
      width: 20px;
      opacity: 0;
      transform: rotate(-45deg);

      @at-root .header__menuButton.is-expanded & {
        opacity: 1;
      }
    }

    &--05 {
      width: 20px;
      opacity: 0;
      transform: rotate(45deg);

      @at-root .header__menuButton.is-expanded & {
        opacity: 1;
      }
    }
  }

  &__nav {
    padding: 27px 50px 0 0;
    margin-left: auto;
  }

  &--lower {
    background-color: transparent;
  }

  &.is-expanded {
    background-color: #fff;
  }

  &.theme-scrolled {
    background-color: #fff;
  }

  .nav {
    display: none;
    align-items: center;

    @include mq(md) {
      display: flex;
    }

    &__item {
      margin-left: 39px;

      &:last-child {
        margin-left: 39px;
      }
    }

    .item {
      position: relative;
      display: block;
      padding: 10px 0;
      font-size: 12px;
      color: #fff;
      letter-spacing: 0.08em;
      transition: color 300ms $ease-out-quad;

      @at-root .header--lower .nav .item {
        color: #000;
      }

      @at-root .header:not(.header--lower).theme-scrolled .nav .item {
        color: #000;
      }

      &::before {
        position: absolute;
        bottom: 4px;
        left: -3px;
        display: block;
        width: calc(100% + 6px);
        height: 1px;
        content: '';
        background-color: #fff;
        opacity: 0;
        transition: background-color 300ms $ease-out-quad;

        @at-root .header--lower .nav .item::before {
          background-color: #04612e;
        }

        @at-root .header:not(.header--lower).theme-scrolled .nav .item::before {
          background-color: #04612e;
        }

        @include mq(md) {
          transition: opacity 300ms $ease-out-quad;

          @at-root .header .nav .item:hover::before {
            opacity: 1;
          }
        }
      }
    }

    .contact-button {
      @include button-fill-sm($bgcolor: #04612e, $width: 100%, $height: 60px, $fontsize: 17px); //stylelint-disable-line

      @include mq(md) {
        @include button-fill-md($width: 198px, $height: 53px, $fontsize: 12px);
      }
    }
  }

  .menu {
    position: absolute;
    top: 0;
    z-index: -1;
    height: 100vh;
    padding-top: 99px;
    visibility: hidden;
    background-color: #196537;
    opacity: 0;
    transition: opacity 300ms, $ease-out-quad visibility 300ms;

    @include mq(md) {
      display: none;
    }

    &__inner {
      height: 100%;
      padding: 0 25px;
      overflow: auto;
    }

    &.is-expanded {
      visibility: visible;
      opacity: 1;
    }
  }

  .primary-nav {
    padding-bottom: 200px;

    &__item {
      border-top: 1px solid #30754b;
    }
  }

  .primary-item {
    position: relative;
    display: block;
    padding: 20px 0;
    font-size: 15px;
    font-weight: 400;
    color: #fff;

    &[data-collapse-toggler] {
      @include mq(md) {
        pointer-events: none;
      }

      &::before,
      &::after {
        position: absolute;
        top: 0;
        right: 1px;
        bottom: 0;
        display: block;
        width: 16px;
        height: 1px;
        margin: auto 0;
        content: '';
        background-color: #fff;
      }

      &::after {
        transition: transform 300ms;
        transform: rotate(-90deg);
      }
    }

    &[aria-expanded='true'] {
      &::after {
        transform: rotate(0);
      }
    }
  }

  .secondary-navWrapper {
    &:not([aria-hidden='false']) {
      height: 0;
      overflow: hidden;
    }
  }

  .secondary-nav {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 24px;

    @include mq(md) {
      padding-top: 1px;
    }

    &__item {
      width: 50%;
      line-height: 1;

      &:nth-child(n + 3) {
        margin-top: 11px;
      }
    }
  }

  .secondary-item {
    position: relative;
    display: block;
    padding: 0 0 0 14px;
    font-size: 12px;
    line-height: 1.23;
    color: #7c9d87;
    letter-spacing: 0.1em;

    &::before {
      position: absolute;
      top: 8px;
      left: 1px;
      display: block;
      width: 3px;
      height: 1px;
      content: '';
      background-color: #7c9d87;
    }
  }

  .other-nav {
    display: flex;
    flex-wrap: wrap;
    width: 240px;
    padding: 27px 0 0;
    margin: 0 auto;

    &__item {
      @include mq(md) {
        width: auto !important; //stylelint-disable-line
      }

      &:nth-child(1) {
        order: 1;
        width: 110px;
      }

      &:nth-child(2) {
        order: 3;
        width: 110px;
        margin-top: 3px;
      }

      &:nth-child(3) {
        order: 2;
      }

      &:nth-child(4) {
        order: 4;
        margin-top: 3px;
      }

      &:nth-child(5) {
        order: 5;
        width: 100%;
        margin-top: 31px;
        text-align: center;
      }
    }
  }

  .other-item {
    font-size: 12px;
    color: #fff;
    letter-spacing: 0.08em;
  }

  .other-nav-button1 {
    @include button-fill-sm($bgcolor: #fff, $textcolor: #196537, $width: 203.5px, $height: 50px); // stylelint-disable-line
  }

  .other-nav-button2 {
    @include button-sm($color: #fff, $width: 203.5px, $height: 50px);

    margin-top: 15px;
  }
}
